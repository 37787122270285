import { TRIP_STATUSES } from "pages/stock-management/trips/constants";

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

export const DISABLE_EDIT_FOR_STATUS = {
  startLocation: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  preferredDate: [TRIP_STATUSES.IN_TRANSIT],
  driverName: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  driverLicenseID: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  truckLicensePlate: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  trailerLicensePlate: [TRIP_STATUSES.IN_TRANSIT, TRIP_STATUSES.EXPIRED],
  addNewSTO: [TRIP_STATUSES.EXPIRED],
  removeEditSTO: [TRIP_STATUSES.EXPIRED],
};

export const getIsDisabledField = (status, field) => DISABLE_EDIT_FOR_STATUS[field].includes(status);

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import CustomButton from "components/Common/buttons/CustomButton";
import { FrownOutlined } from "@ant-design/icons";

const useStyles = makeStyles({
  notFoundContainer: {
    position: "relative",
    color: "rgba(68, 68, 68, 1)",
  },
  linkText: {
    marginTop: "15%",

    "&>span:first-child": {
      fontSize: 150,
      display: "block",
    },
    "&>span:nth-child(2)": {
      fontWeight: 200,
      fontSize: 60,
      display: "block",
    },
    "&>span:nth-child(3)": {
      fontWeight: 100,
      marginBottom: 20,
      fontSize: 30,
      display: "block",
    },
  },
  linkContainer: {
    width: "100%",
    height: "100%",
    minHeight: "100vh",
    zIndex: 9,
    background: "#ffffffeb",
    textAlign: "center",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    justifyContent: "center",
  },
});

const NotFoundContainer = ({
  isNotFound = false,
  backUrl = "",
  text = "Oops! The requested data is not found.",
  children,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleBack = useCallback(() => (backUrl ? history.push(backUrl) : history.goBack()), [backUrl]);

  if (isNotFound)
    return (
      <div className={classes.notFoundContainer}>
        {children}
        <div className={classes.linkContainer}>
          <div className={classes.linkText}>
            <FrownOutlined />
            <span>404</span>
            <span>{text}</span>
            <CustomButton size={"small"} onClick={handleBack} type="primary" text="Go Back" />
          </div>
        </div>
      </div>
    );
  return children;
};

export default NotFoundContainer;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty, uniq } from "lodash";
import { AUTH_USER, FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { userHasAdminRole } from "utils/helpers/functions";
import TSRSelect from "components/Common/components/TSRSelect";
import { getUsersList } from "utils/api";

const { Option } = Select;

const filterOptions = [
  { name: "TSR", short: "TSR", value: 1, id: "assignedToName" },
  { name: "Ticket", short: "TCT", value: 2, id: "odooTicketNumber" },
  { name: "Category", short: "CTG", value: 3, id: "category" },
  { name: "Customer Name", short: "CN", value: 4, id: "customerName" },
  { name: "Customer ID", short: "CID", value: 5, id: "customerId" },
  { name: "Customer Phone", short: "CPH", value: 6, id: "customerPhone" },
  ...(userHasAdminRole() ? [{ name: "Depot", short: "DPT", value: 7, id: "depot" }] : []),
  { name: "Source", short: "SRC", value: 8, id: "source" },
];

const useStyles = makeStyles({
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    height: 60,
    overflow: "auto",

    "& .ant-select-selection-overflow": {
      flexWrap: "nowrap",
    },
  },
  filterSelect: {
    "& .ant-select": {
      minWidth: "auto!important",
    },
  },
});

const TasksFilter = props => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { onFilter, showCategory } = props;
  const [filterType, setFilterType] = useState([]);
  const {
    list: { depot },
  } = useSelector(DictionariesSelector);
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.TASK_CANCELLED);
  const [TSRList, setTSRList] = useState([]);

  useEffect(() => {
    if (!isEmpty(filters)) {
      const defaultFilter = filterOptions.filter(item => item.id in filters);
      setFilterType(defaultFilter.map(item => item.value));
      form.setFieldsValue(filters);
    }
    getUsersList({
      role: [USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS].join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
  }, []);

  const selectedFilterValues = useMemo(
    () => filterType?.map(item => filterOptions[item - 1]),
    [filterType, filterOptions]
  );
  const onChange = useCallback(value => setFilterType(value), []);

  const handleOnFilter = useCallback(values => {
    const { pageSize } = getFilters();
    const preparedData = Object.keys(values).reduce((a, item) => ({ ...a, [item]: values[item]?.trim() }), {});
    onFilter({ ...preparedData, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={handleOnFilter}>
        <div className={classnames(classes.filtersContainer, selectedFilterValues.length > 2 && classes.filterSelect)}>
          <Select
            showSearch
            mode="multiple"
            tokenSeparators={[" ", ","]}
            placeholder={"Select type"}
            value={selectedFilterValues}
            onChange={onChange}
            optionLabelProp={selectedFilterValues.length > 3 ? "short" : "label"}
            allowClear
          >
            {filterOptions.map(item => (
              <Option key={item.value} title={item.name} value={item.value} label={item.name} short={item.short}>
                {item.name}
              </Option>
            ))}
          </Select>

          {!!filterType.length && filterType.includes(1) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="assignedTo" rules={[{ required: true, message: "Please select TSR name" }]}>
                <TSRSelect
                  showSearch
                  initialList={TSRList}
                  roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS]}
                  depot={AUTH_USER?.depotName}
                  placeholder="Please select TSR"
                />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(2) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="odooTicketNumber" rules={[{ required: true, message: "Please enter ticket number" }]}>
                <Input
                  style={{ width: "100%" }}
                  size={"default"}
                  placeholder={"Enter ticket number"}
                  allowClear
                  pattern={inputTextPattern}
                  title={inputPatternTitle}
                />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(3) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="category" rules={[{ required: true, message: "Please select task category" }]}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  size={"default"}
                  placeholder={"Select task category"}
                  allowClear
                >
                  {showCategory.length > 0 &&
                    showCategory.map(
                      item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ),
                      this
                    )}
                </Select>
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(4) && (
            <>
              <div className="filter-divider" />
              <Form.Item
                name="customerName"
                rules={[{ required: true, message: "Please enter first or last name of customer" }]}
              >
                <Input
                  style={{ width: "100%" }}
                  size={"default"}
                  placeholder={"Enter first or last name of customer"}
                  allowClear
                  pattern={inputTextPattern}
                  title={inputPatternTitle}
                />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(5) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="customerId" rules={[{ required: true, message: "Please enter customer ID" }]}>
                <Input
                  style={{ width: "100%" }}
                  size={"default"}
                  placeholder={"Enter customer ID"}
                  allowClear
                  pattern={inputTextPattern}
                  title={inputPatternTitle}
                />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(6) && (
            <>
              <div className="filter-divider" />
              <Form.Item
                name="customerPhone"
                rules={[{ required: true, message: "Please enter customer's phone number" }]}
              >
                <Input
                  style={{ width: "100%" }}
                  size={"default"}
                  placeholder={"Enter customer's phone number"}
                  allowClear
                  pattern={inputTextPattern}
                  title={inputPatternTitle}
                />
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType?.includes(7) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="depot" rules={[{ required: true, message: "Please select depot" }]}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  size={"default"}
                  placeholder={"Select depot"}
                  allowClear
                >
                  {depot[USER_TENANT_NAME] &&
                    uniq(depot[USER_TENANT_NAME])
                      ?.sort()
                      ?.map(item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ))}
                </Select>
              </Form.Item>
            </>
          )}
          {!!filterType.length && filterType.includes(8) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="source" rules={[{ required: true, message: "Please enter source" }]}>
                <Input style={{ width: "100%" }} size={"default"} placeholder={"Please enter source"} allowClear />
              </Form.Item>
            </>
          )}
        </div>
        <CustomButton type="primary" htmlType="submit" text="Filter" size="small" />
      </Form>
    </div>
  );
};

TasksFilter.propTypes = {
  filterType: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showStatus: PropTypes.array,
  showCategory: PropTypes.array,
};

export default TasksFilter;

import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AssetItem from "pages/stock-management/trips/tripDetails/AssetItem";
import EmptyAssetItem from "pages/stock-management/trips/tripDetails/EmptyAssetItem";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import closeIcon from "assets/images/svg/closeSmall.svg";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { closeTrip, unassignSTO } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
  remove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "#F8F8F8",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});

const StopItem = ({ stop, showActions = false, update, tripNumber, lastStopType, disableStatusList = [] }) => {
  const [isLoading, setLoader] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { location, assets, loadedAssets, offloadedAssets, status, id } = stop;
  const classes = useStyles();

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const onRemoveStop = useCallback(() => {
    setLoader(true);
    unassignSTO(tripNumber, {
      stopId: id,
    })
      .then(async () => {
        if (lastStopType === "last") {
          await closeTrip(tripNumber);
        }
        closeConfirmationModal();
        setLoader(false);
        successMessage("Stop was removed successfully");
        update();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [id, lastStopType]);

  const desc = useMemo(() => {
    switch (lastStopType) {
      case "hasOnly2":
        return "You are removing the last not passed stop from the trip with only 2 stops. In this case trip will NOT be completed automatically and you need to add a new last stop. Are you sure you want this?";
      case "last":
        return "You are removing the last not passed stop from the trip. In this case trip will be completed automatically. Are you sure you want this?";
      default:
        return "Are you sure you want to remove this stop?";
    }
  }, [lastStopType]);

  return (
    <div className={disableStatusList.includes(status) && classes.disabled}>
      <div className={classes.titleContainer}>
        <TitleWithDivider
          title={location?.depot}
          button={
            showActions && (
              <div className={classes.remove} onClick={openConfirmationModal}>
                <img src={closeIcon} alt="remove" />
              </div>
            )
          }
        />
      </div>
      {assets.length ? (
        assets.map(asset => (
          <AssetItem
            key={asset.id}
            tripNumber={tripNumber}
            asset={asset}
            loadedAssets={loadedAssets}
            offloadedAssets={offloadedAssets}
            update={update}
            isEdit={showActions}
          />
        ))
      ) : (
        <EmptyAssetItem />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          description={desc}
          onCancel={closeConfirmationModal}
          onConfirm={onRemoveStop}
          loading={isLoading}
          cancelBtnTitle="Cancel"
          confirmBtnTitle="Confirm"
        />
      )}
    </div>
  );
};

export default StopItem;

import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Modal } from "antd";
import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import classnames from "classnames";
import { dateAgo } from "utils/helpers/functions";
import CustomLink from "components/Common/buttons/CustomLink";
import MeterPinModal, { FLOW_TYPES } from "pages/customer-management/customerDetails/MeterPinModal";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  informationContainer: {
    display: "flex",
    width: "50%",
    paddingRight: 25,
  },
  mapContainer: {
    width: "50%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.06)",
    paddingLeft: 25,
  },
  column: {
    width: "100%",
  },
  rowData: {
    display: "flex",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "50%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
  },
  meterPinStatus: {
    display: "flex",
    flexDirection: "row",
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "50%",
    "& div": {
      marginLeft: 10,
    },
  },
  dataError: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    color: "red",
    width: "50%",
  },
  img: {
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginBottom: 14,
  },
  green: {
    color: "#00b050",
  },
  red: {
    color: "#ff4d4f",
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  link: {
    textDecoration: "underline!important",
  },
});

const PersonalInformationTab = ({
  customer,
  consumptionStat,
  lastCylinderChange,
  photoDetails,
  meterPinDetails,
  isHavePXMeter,
}) => {
  const classes = useStyles();
  const [previewUrl, setPreviewUrl] = useState("");
  const [openSetPinModal, setOpenSetPinModal] = useState("");
  const {
    location,
    gender,
    document,
    technicianName,
    familySize,
    packageName,
    packageType,
    packageCode,
    secondaryPhone,
    id,
    phone,
    technician,
    occupation,
    meterNumber,
    installationDate,
    refSource,
    refValue,
    type,
    closeRelativePhone,
    payeePhoneNumbers,
    status,
  } = customer;

  const onPreviewImg = useCallback(
    e => {
      const url = e.currentTarget.dataset.url;
      setPreviewUrl(url);
    },
    [id]
  );

  const onClose = useCallback(() => setPreviewUrl(""), [id]);

  const openSetPin = useCallback(e => {
    e.preventDefault();
    const flow = e.target.dataset.flow;
    setOpenSetPinModal(flow);
  }, []);
  const closeSetPin = useCallback(() => setOpenSetPinModal(false), []);

  const phonesList = useMemo(
    () => [
      { label: "Primary Phone", value: phone },
      { label: "Secondary Phone", value: secondaryPhone },
      { label: "Close Relative Phone", value: closeRelativePhone },
      ...((payeePhoneNumbers && payeePhoneNumbers?.map(phone => ({ label: "Transaction", value: phone }))) || []),
    ],
    [phone, secondaryPhone, closeRelativePhone, payeePhoneNumbers]
  );
  return (
    <div className={classes.root}>
      <div className={classes.informationContainer}>
        <div className={classes.column}>
          <div className={classes.sectionTitle}>Account Details</div>
          <div className={classes.rowData}>
            <div className={classes.title}>Customer ID</div>
            <div className={classes.data}>{textFormatter(id)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Phone</div>
            <div className={classes.data}>{textFormatter(phone)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Secondary Phone</div>
            <div className={classes.data}>{textFormatter(secondaryPhone)}</div>
          </div>
          {location && (
            <>
              <div className={classes.rowData}>
                <div className={classes.title}>Location (region, district)</div>
                <div className={classes.data}>{`${location?.region}, ${location?.district}`}</div>
              </div>
              <div className={classes.rowData}>
                <div className={classes.title}>Address</div>
                <div className={classes.data}>{`${location?.street} ${location?.houseNumber}`}</div>
              </div>
              <div className={classes.rowData}>
                <div className={classes.title}>LatLng</div>
                <div className={classes.data}>{`${location?.lat}, ${location?.lon}`}</div>
              </div>
            </>
          )}
          <div className={classes.rowData}>
            <div className={classes.title}>TSR Email</div>
            <div className={classes.data}>
              <CustomLink underlined target="_blank" to={`/user/${technician}`}>
                {textFormatter(technician)}
              </CustomLink>
            </div>
          </div>

          <div className={classes.rowData}>
            <div className={classes.title}>TSR</div>
            <div className={classes.data}>{textFormatter(technicianName)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Family Size</div>
            <div className={classes.data}>{textFormatter(familySize)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Personal Photo</div>
            <div className={classes.data}>
              {photoDetails?.personalPhotoUrl ? (
                <div className={classes.img}>
                  <img
                    data-url={photoDetails?.personalPhotoUrl}
                    onClick={onPreviewImg}
                    src={photoDetails?.personalPhotoUrl}
                    alt="preview"
                  />
                </div>
              ) : (
                "No photo"
              )}
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>House Photo</div>
            <div className={classes.data}>
              {photoDetails?.housePhotoUrl ? (
                <div className={classes.img}>
                  <img
                    data-url={photoDetails?.housePhotoUrl}
                    onClick={onPreviewImg}
                    src={photoDetails?.housePhotoUrl}
                    alt="preview"
                  />
                </div>
              ) : (
                "No photo"
              )}
            </div>
          </div>

          <div className={classes.rowData}>
            <div className={classes.title}>Lead Source</div>
            <div className={classes.data}>
              {textFormatter(refSource)}
              {!!refValue && refValue.length === 7 ? (
                <CustomLink className={classes.link} target="_blank" to={`/customers/customer/${refValue}`}>
                  {refValue}
                </CustomLink>
              ) : (
                refValue
              )}
            </div>
          </div>

          <Divider />
          <div className={classes.sectionTitle}>Bio Data</div>
          <div className={classes.rowData}>
            <div className={classes.title}>Gender</div>
            <div className={classes.data}>{textFormatter(gender)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Occupation</div>
            <div className={classes.data}>{textFormatter(occupation)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Lead Category</div>
            <div className={classes.data}>{textFormatter(type)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>ID Type</div>
            <div className={classes.data}>{textFormatter(document?.type)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>ID Number</div>
            <div className={classes.data}>{textFormatter(document?.number)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>ID Photo</div>
            <div className={classes.data}>
              {photoDetails?.idPhotoUrl ? (
                <div className={classes.img}>
                  <img
                    data-url={photoDetails?.idPhotoUrl}
                    onClick={onPreviewImg}
                    src={photoDetails?.idPhotoUrl}
                    alt="preview"
                  />
                </div>
              ) : (
                "No photo"
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.mapContainer}>
        <div className={classes.sectionTitle}>Meter Data</div>
        <div className={classes.rowData}>
          <div className={classes.title}>Meter Number</div>
          <div className={classes.data}>{textFormatter(meterNumber)}</div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Last Connected</div>
          <div className={classes.data}>
            {textFormatter(
              consumptionStat?.meterLastConnectedTime
                ? customDateFormatWithTime(consumptionStat?.meterLastConnectedTime)
                : ""
            )}
          </div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Battery Level</div>
          <div className={classnames(classes.data, consumptionStat?.batteryLevel >= 20 ? classes.green : classes.red)}>
            {textFormatter(
              !isNaN(consumptionStat?.batteryVoltage) && !isNaN(consumptionStat?.batteryLevel)
                ? `${consumptionStat?.batteryVoltage}V (${consumptionStat?.batteryLevel}%)`
                : ""
            )}
          </div>
        </div>

        {/*{isHavePXMeter && status === "Active" && (*/}
        {/*  <div className={classes.rowData}>*/}
        {/*    <div className={classes.title}>Meter Child Lock</div>*/}
        {/*    <div*/}
        {/*      className={classnames(classes.meterPinStatus, meterPinDetails?.pinStatus === "Active" && classes.green)}*/}
        {/*    >*/}
        {/*      {meterPinDetails?.pinStatus}*/}
        {/*      <div>*/}
        {/*        {meterPinDetails?.pinStatus === "Active" && (*/}
        {/*          <>*/}
        {/*            <a className={classes.link} href={""} data-flow={FLOW_TYPES.DEACTIVATE} onClick={openSetPin}>*/}
        {/*              Deactivate*/}
        {/*            </a>*/}
        {/*            <span> - </span>*/}
        {/*          </>*/}
        {/*        )}*/}
        {/*        <a className={classes.link} href={""} data-flow={FLOW_TYPES.ACTIVATE} onClick={openSetPin}>*/}
        {/*          Set Meter Pin*/}
        {/*        </a>*/}
        {/*        {meterPinDetails?.pinStatus === "Active" && (*/}
        {/*          <>*/}
        {/*            <span> - </span>*/}
        {/*            <a className={classes.link} href={""} data-flow={FLOW_TYPES.REMIND} onClick={openSetPin}>*/}
        {/*              Remind Meter PIN*/}
        {/*            </a>*/}
        {/*          </>*/}
        {/*        )}*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*)}*/}

        <Divider />
        <div className={classes.sectionTitle}>Cylinder Data</div>
        <div className={classes.rowData}>
          <div className={classes.title}>Last Cylinder Change</div>
          <div className={classes.data}>
            {lastCylinderChange ? (
              <>{textFormatter(`${lastCylinderChange} days ago`)}</>
            ) : (
              textFormatter(lastCylinderChange)
            )}
          </div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Gas Level</div>
          <div className={classes.data}>
            {textFormatter(consumptionStat?.gasLevel ? `${consumptionStat?.gasLevel} Kg` : "")}
          </div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Age As Customer</div>
          <div className={classes.data}>{textFormatter(installationDate ? dateAgo(installationDate) : "")}</div>
        </div>

        <Divider />
        <div className={classes.sectionTitle}>Package Data</div>
        <div className={classes.rowData}>
          <div className={classes.title}>Package Name</div>
          <div className={classes.data}>{textFormatter(packageName)}</div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Package Type</div>
          <div className={classes.data}>{textFormatter(packageType)}</div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Package Code</div>
          <div className={classes.data}>
            {packageCode ? (
              <CustomLink underlined to={`/packages/${packageCode}`} target={"_blank"}>
                {textFormatter(packageCode)}
              </CustomLink>
            ) : (
              textFormatter(packageCode)
            )}
          </div>
        </div>

        <Divider />
        <div className={classes.sectionTitle}>Payment Data</div>
        <div className={classes.rowData}>
          <div className={classes.title}>Amount Spent Since Last Cylinder Change</div>
          <div className={classes.data}>{textFormatter(consumptionStat?.amountSpentSinceLastCylinderChange)}</div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Total Payment Last 30 Days</div>
          <div className={classes.data}>{textFormatter(consumptionStat?.totalPaymentLast30Days)}</div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Average Payment Last 6 Months</div>
          <div className={classes.data}>{textFormatter(consumptionStat?.averagePaymentLast6Month)}</div>
        </div>

        <Divider />
        <div className={classes.sectionTitle}>Consumption Information</div>
        <div className={classes.rowData}>
          <div className={classes.title}>Total Consumption Last 30 Days</div>
          <div className={classes.data}>{textFormatter(consumptionStat?.totalConsumptionLast30Days)}</div>
        </div>
        <div className={classes.rowData}>
          <div className={classes.title}>Average Consumption Last 6 Months</div>
          <div className={classes.data}>{textFormatter(consumptionStat?.averageConsumptionLast6Month)}</div>
        </div>
      </div>
      {previewUrl && (
        <Modal title="Photo preview" open={!!previewUrl} onCancel={onClose} footer={[]}>
          <img className={classes.imgPreview} src={previewUrl} alt="preview" />
        </Modal>
      )}
      {!!openSetPinModal && (
        <MeterPinModal flow={openSetPinModal} onClose={closeSetPin} phonesList={phonesList} customerId={id} />
      )}
    </div>
  );
};

export default PersonalInformationTab;

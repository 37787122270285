import React, { useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import NGIInput from "components/Common/inputs/NGIInput";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { ASSET_TYPES } from "pages/stock-management/stockTransferOrders/NewSTO/constants";
import { warnMessage } from "components/Common/responses/message";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import classnames from "classnames";

const useStyles = makeStyles({
  assetItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f5f5f5",
    borderRadius: 50,
    padding: 14,
    marginBottom: 10,
  },
  remove: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  assetType: {
    width: "32%",
  },
  assetCondition: {
    width: "32%",
  },
  assetQTY: {
    width: "33%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "&>input": {
      width: "85%",
      height: 40,
      backgroundColor: "#FFF!important",
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.5,
  },
});

const NewAssetRow = ({ item, index, setAssets, type, onRemoveAsset, isEditTrip }) => {
  const classes = useStyles();
  const {
    list: { stoAssetGroupConditions },
  } = useSelector(DictionariesSelector);

  const handleGroupChange = useCallback(
    value =>
      setAssets(prevState => {
        prevState[index].group = value;
        prevState[index].condition = null;
        return [...prevState];
      }),
    [index]
  );

  const handleConditionChange = useCallback(
    value =>
      setAssets(prevState => {
        prevState[index].condition = value;
        return [...prevState];
      }),
    [index]
  );

  const handleQtyChange = useCallback(
    event =>
      setAssets(prevState => {
        const value = event.target.value;
        if (value && (Number(value) < 1 || Number(value) > 2000)) {
          warnMessage("Cannot be 0 or less, max 2000");
        } else if (!isNaN(value)) {
          prevState[index].quantity = Number(value);
        }
        return [...prevState];
      }),
    [index]
  );

  const mappedConditions = useMemo(() => {
    const conditions = {};
    stoAssetGroupConditions?.forEach(item => {
      conditions[item.group] = {
        ...conditions[item.group],
        ...item.stoTypes.reduce((a, k) => ({ ...a, [k]: item.conditions }), {}),
      };
    });
    return conditions;
  }, []);

  const conditionList = useMemo(
    () => (type && item.group ? mappedConditions[item.group][type] : []),
    [type, item.group]
  );

  return (
    <div className={classes.assetItem}>
      <NGISelect
        className={classes.assetType}
        size="small"
        onSelect={handleGroupChange}
        placeholder="Select asset type"
        value={item.group}
        disabled={isEditTrip}
      >
        {ASSET_TYPES.map(item => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}
      </NGISelect>
      <NGISelect
        className={classes.assetCondition}
        size="small"
        disabled={!conditionList || isEditTrip}
        value={item.condition}
        onChange={handleConditionChange}
        placeholder="Select condition"
      >
        {conditionList &&
          conditionList.map(item => (
            <Option key={item} value={item}>
              {item}
            </Option>
          ))}
      </NGISelect>
      <div className={classes.assetQTY}>
        <NGIInput size="small" value={item.quantity} placeholder="Enter QTY" onChange={handleQtyChange} />
        <div
          className={classnames(classes.remove, isEditTrip && classes.disabled)}
          data-id={item.id}
          onClick={onRemoveAsset}
        >
          <img src={closeIcon} alt="remove" />
        </div>
      </div>
    </div>
  );
};

export default NewAssetRow;

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DatePicker, Form, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES, USER_TENANT_NAME } from "utils/constants";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { uniq } from "lodash";
import { TSRSelector } from "store/TSR/selectors";
import { getFormattedKeyName } from "utils/helpers/functions";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import TSRSelect from "components/Common/components/TSRSelect";

const { Option } = Select;
const { RangePicker } = DatePicker;

const TYPES = ["assigneeId", "depot", "assigneeRole"];
const RoutineTaskFilters = ({ onFilter, defaultFilter, status }) => {
  const [form] = Form.useForm();
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.ROUTINE_TASK[status]);
  const [activeFilter, setActiveFilter] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const {
    list: { depot, agentRoles },
  } = useSelector(DictionariesSelector);
  const { TSRList } = useSelector(TSRSelector);

  useEffect(() => {
    const filterKey = Object.keys(defaultFilter)[0];
    if (TYPES.includes(filterKey)) {
      form.setFieldsValue({
        type: filterKey,
        value: defaultFilter[filterKey],
      });
      setActiveFilter(filterKey);
    }
  }, [defaultFilter]);

  useEffect(() => {
    const filterKey = Object.keys(filters)[0];
    if (TYPES.includes(filterKey)) {
      form.setFieldsValue({
        type: filterKey,
        value: filters[filterKey],
      });
      setActiveFilter(filterKey);
    }
  }, [filters]);

  const handleFilterChange = useCallback(item => {
    setActiveFilter(item);
    form.setFieldsValue({ value: null });
  }, []);

  const onFinish = useCallback(
    values => {
      const { type, value } = values;
      const { pageSize } = getFilters();
      const normalizedData = { [type]: value?.trim(), pageSize };
      if (!!dateRange && type === "assigneeId") {
        normalizedData.updatedAtFrom = getStartOfTheDay(dateRange[0]);
        normalizedData.updatedAtTo = getEndOfTheDay(dateRange[1]);
      }
      onFilter(normalizedData);
    },
    [dateRange]
  );

  const onDateRangeChange = useCallback(value => setDateRange(value), []);

  const disableDate = useCallback(current => current && current.valueOf() > Date.now(), []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <Form.Item name="type">
          <Select showSearch onChange={handleFilterChange} placeholder={"Select type"}>
            <Option value="assigneeId">Assignee</Option>
            <Option value="depot">Depot</Option>
            <Option value="assigneeRole">Role</Option>
          </Select>
        </Form.Item>
        {activeFilter === "assigneeId" && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <TSRSelect
                showSearch
                initialList={TSRList}
                roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                depot={AUTH_USER?.depotName}
                placeholder="Please select TSR"
              />
            </Form.Item>
            <div className="filter-divider" />
            <RangePicker
              inputReadOnly={true}
              value={dateRange}
              onChange={onDateRangeChange}
              disabledDate={disableDate}
            />
          </>
        )}
        {activeFilter === "depot" && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <Select showSearch placeholder={"Select value"}>
                {depot[USER_TENANT_NAME] &&
                  uniq(depot[USER_TENANT_NAME])
                    ?.sort()
                    ?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
              </Select>
            </Form.Item>
          </>
        )}

        {activeFilter === "assigneeRole" && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <Select showSearch placeholder={"Select value"}>
                {agentRoles?.map(item => (
                  <Option key={item} value={item}>
                    {getFormattedKeyName(item)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </>
        )}

        <CustomButton type="primary" htmlType="submit" text="Filter" size="small" />
      </Form>
    </div>
  );
};

RoutineTaskFilters.propTypes = {
  filterType: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default RoutineTaskFilters;

import { Container } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Header from "./Header";
import { Card, CardBody } from "reactstrap";
import { Col, Modal, Row, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import {
  cancelTrip,
  fetchHistoryByType,
  markTripAsPlanned,
  retrieveAllAssets,
  retrieveTripDetails,
  retrieveTripSeal,
  stopTrip,
} from "utils/api";
import { useParams } from "react-router";
import { ASSET_TYPES, TABS, TABS_BUTTONS, tripHistoryColumns } from "./constants";
import StopItem from "pages/stock-management/trips/tripDetails/StopItem";
import { textFormatter } from "components/Common/formatter";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { preparedHistoryData } from "./utils";
import AllAssetsTab from "pages/stock-management/trips/tripDetails/AllAssetsTab";
import ReportTab from "pages/stock-management/trips/tripDetails/ReportTab";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import useStoreFilters from "customHooks/useStoreFilters";
import CustomButton from "components/Common/buttons/CustomButton";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 30,
    padding: 30,
  },
  cardBody: {
    padding: 0,
  },
  tabs: {
    display: "flex",
    padding: "0 30px",
    fontSize: 13,
    fontWeight: 600,
  },
  active: {
    backgroundColor: "#FFFFFF!important",
  },
  tab: {
    boxShadow: "0px -10px 20px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#E1E1E1",
    padding: 18,
    borderRadius: "10px 10px 0px 0px",
    marginRight: 10,

    "&:hover": {
      cursor: "pointer",
    },
  },
  row: {
    lineHeight: 3,
  },
  displayFlex: {
    display: "flex",
  },
  leftText: {
    display: "flex",
    flexDirection: "column",
    marginRight: 20,
    minWidth: 200,
  },
  rightText: {
    display: "flex",
    flexDirection: "column",
  },
  img: {
    marginRight: 5,
    marginBottom: 5,
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,

    "& button + button": {
      marginLeft: 10,
    },
  },
});

const TripDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [tripHistory, setTripHistory] = useState([]);
  const [trip, setTrip] = useState({});
  const [activeTab, setActiveTab] = useState("GENERAL");
  const [assetsInTruck, setAssetsInTruck] = useState(null);
  const [hasAssets, setHasAssets] = useState(false);
  const [sealData, setSealData] = useState();
  const [previewUrl, setPreviewUrl] = useState("");
  const { setFilters } = useStoreFilters(FILTER_PAGE.ASSETS_IN_TRUCK);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const { stops, startLocation, startDate, startTime, vehicle, extraAssets, lostAssets, report, status } = trip;

  const onTabClick = useCallback(e => {
    const tab = e.target.dataset.tab;
    setActiveTab(tab);
  }, []);

  const onPreviewImg = useCallback(e => setPreviewUrl(e.currentTarget.dataset.url), []);
  const onClose = useCallback(() => setPreviewUrl(""), []);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  const onMarkAsPlanned = useCallback(() => {
    markTripAsPlanned(id)
      .then(() => {
        setIsLoading(false);
        successMessage("The Trip marked as Planned!");
        onRetrieveTripDetails();
      })
      .catch(err => {
        setIsLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const onStopTrip = useCallback(
    values => {
      setIsLoading(true);
      stopTrip(id, values)
        .then(() => {
          successMessage("Trip was stopped successfully");
          setIsLoading(false);
          onRetrieveTripDetails();
          closeConfirmationModal();
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [id]
  );

  const onRetrieveTripDetails = useCallback(() => {
    setIsLoading(true);
    updateAllAssets();
    retrieveTripDetails(id)
      .then(({ data }) => {
        setTrip(data.result);
        setIsLoading(false);
        setIsHistoryLoading(true);
        fetchHistoryByType("trips", data?.result?.historyId)
          .then(({ data }) => {
            setIsHistoryLoading(false);
            const preparedHistory = preparedHistoryData(data?.result?.items);
            setTripHistory({
              result: { items: preparedHistory },
            });
          })
          .catch(() => {
            setIsHistoryLoading(false);
            setTripHistory({
              result: { items: [] },
            });
          });
      })
      .catch(err => {
        setIsLoading(false);
        defaultNgiErrorMethod(err);
      });

    retrieveTripSeal(id).then(({ data }) => {
      setSealData(data.result);
    });
  }, []);

  const updateAllAssets = useCallback(params => {
    setFilters(params);
    retrieveAllAssets({ assetTransferTripId: id, ...params }).then(({ data }) => {
      setAssetsInTruck(data);
      setHasAssets(!!data?.result?.items?.length);
    });
  }, []);

  const onCancelTrip = useCallback(
    reason => {
      setIsLoading(true);
      cancelTrip(id, { reason })
        .then(() => {
          successMessage("Trip was canceled successfully");
          setIsLoading(false);
          onRetrieveTripDetails();
          closeConfirmationModal();
        })
        .catch(e => {
          setIsLoading(false);
          defaultNgiErrorMethod(e);
        });
    },
    [id]
  );

  useEffect(() => {
    onRetrieveTripDetails();
  }, []);

  return (
    <div className="page-content">
      <Container fluid maxWidth={"xl"}>
        <Header id={id} trip={trip} hasAssets={hasAssets} onMarkAsPlanned={onMarkAsPlanned} onStopTrip={onStopTrip} />
        <div className={classes.root}>
          <div className={classes.tabs}>
            {TABS_BUTTONS.map(item =>
              item.key === TABS.REPORT && status !== "Completed" ? null : (
                <div
                  key={item.key}
                  className={classnames(classes.tab, activeTab === item.key && classes.active)}
                  data-tab={item.key}
                  onClick={onTabClick}
                >
                  {item.title}
                </div>
              )
            )}
          </div>
          <Card className={classes.container}>
            <CardBody className={classes.cardBody}>
              <Spin spinning={isLoading}>
                {activeTab === TABS.GENERAL && (
                  <div className={classes.displayFlex}>
                    <div className={classes.leftText}>
                      <span className={classes.row}>Start Location</span>
                      <span className={classes.row}>Trip Date</span>
                      <span className={classes.row}>Trip Time</span>
                      <span className={classes.row}>Driver License ID</span>
                      <span className={classes.row}>Driver Name</span>
                      <span className={classes.row}>Truck License Plate</span>
                      <span className={classes.row}>Trailer License Plate</span>
                      <span className={classes.row}>Stop Reason</span>
                      {sealData?.sealNumber && <span className={classes.row}>Seal Number</span>}
                      {sealData?.photos && <span className={classes.row}>Seal Photos</span>}
                    </div>
                    <div className={classes.rightText}>
                      <span className={classes.row}>
                        <b>{textFormatter(startLocation)}</b>
                      </span>
                      <span className={classes.row}>
                        <b>{textFormatter(startDate)}</b>
                      </span>
                      <span className={classes.row}>
                        <b>{textFormatter(startTime)}</b>
                      </span>
                      <span className={classes.row}>
                        <b>{textFormatter(vehicle?.driverLicenseID)}</b>
                      </span>
                      <span className={classes.row}>
                        <b>{textFormatter(vehicle?.driverName)}</b>
                      </span>
                      <span className={classes.row}>
                        <b>{textFormatter(vehicle?.truckLicensePlate)}</b>
                      </span>
                      <span className={classes.row}>
                        <b>{textFormatter(vehicle?.trailerLicensePlate)}</b>
                      </span>
                      <span className={classes.row}>
                        <b>{textFormatter(vehicle?.reason)}</b>
                      </span>
                      {sealData?.sealNumber && (
                        <span className={classes.row}>
                          <b>{textFormatter(sealData.sealNumber)}</b>
                        </span>
                      )}
                      {sealData?.photos?.length && (
                        <span className={classes.row}>
                          {sealData?.photos.map(item => (
                            <div key={item} className={classes.img}>
                              <img data-url={item} onClick={onPreviewImg} src={item} alt="preview" />
                            </div>
                          ))}
                        </span>
                      )}
                    </div>
                    {previewUrl && (
                      <Modal title="Photo preview" open={!!previewUrl} onCancel={onClose} footer={[]}>
                        <img className={classes.imgPreview} src={previewUrl} alt="preview" />
                      </Modal>
                    )}
                  </div>
                )}
                {activeTab === TABS.STOPS && (
                  <div>
                    <Row>
                      <Col span={24}>
                        {stops?.map(stop => (
                          <StopItem key={stop.id} stop={stop} />
                        ))}
                      </Col>
                    </Row>
                  </div>
                )}
                {activeTab === TABS.ASSETS && (
                  <AllAssetsTab
                    filtersType={FILTER_PAGE.ASSETS_IN_TRUCK}
                    assets={assetsInTruck}
                    update={updateAllAssets}
                    id={id}
                    type={ASSET_TYPES.ALL}
                  />
                )}
                {activeTab === TABS.EXTRA_ASSETS && (
                  <AllAssetsTab
                    assets={{
                      result: { items: extraAssets },
                    }}
                    update={updateAllAssets}
                    showActions={false}
                    type={ASSET_TYPES.EXTRA}
                  />
                )}
                {activeTab === TABS.LOST_ASSETS && (
                  <AllAssetsTab
                    assets={{
                      result: { items: lostAssets },
                    }}
                    update={updateAllAssets}
                    showActions={false}
                    type={ASSET_TYPES.LOST}
                  />
                )}
                {activeTab === TABS.HISTORY && (
                  <NGITable isLoading={isHistoryLoading} columns={tripHistoryColumns()} data={tripHistory} />
                )}
                {activeTab === TABS.REPORT && <ReportTab stops={stops} closingReport={report} />}
              </Spin>
            </CardBody>
            <div className={classes.buttonsContainer}>
              {(trip?.status === "Expired" || trip?.status === "Pending" || trip?.status === "Planned") && (
                <CustomButton
                  roles={[USER_ROLES.LOGISTICS]}
                  onClick={openConfirmationModal}
                  type="error"
                  size="small"
                  text="Cancel Trip"
                />
              )}
            </div>
          </Card>
        </div>
        {isConfirmationOpen && (
          <ConfirmationModal
            description="Are you sure you want to cancel this strip?"
            onCancel={closeConfirmationModal}
            onConfirm={onCancelTrip}
            loading={isLoading}
            cancelBtnTitle="Close"
            confirmBtnTitle="Cancel Trip"
            withComment
          />
        )}
      </Container>
    </div>
  );
};

export default TripDetails;

import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { availableStatuses, dateFormat, searchOptions } from "pages/TAM/TAMRequests/constants";
import { makeStyles } from "@material-ui/core/styles";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import moment from "moment/moment";
import TSRSelect from "components/Common/components/TSRSelect";

const { RangePicker } = DatePicker;
const { Option } = Select;

const useStyles = makeStyles({
  root: { width: "250px!important" },
});
const TAMRequestsSearch = ({ handleSearch, TSRList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { getFilters, filters } = useStoreFilters(FILTER_PAGE.TAM_REQUESTS);
  const [selectedFilter, setSelectedFilter] = useState("");
  const {
    list: { TAMReasons },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (filters?.createdAtFrom) {
        form.setFieldsValue({
          type: "date",
          date: [moment(filters?.createdAtFrom), moment(filters?.createdAtTo)],
          value: null,
        });
        setSelectedFilter("date");
      }
      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: "status",
        value: "Pending",
      });
    }
  }, []);

  const onChangeFilter = useCallback(
    value => {
      setSelectedFilter(value);
      form.setFieldValue("value", null);
    },
    [form]
  );

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    if (values?.type === "date") {
      const filter = { pageSize };
      filter.createdAtFrom = getStartOfTheDay(moment(values?.date[0]));
      filter.createdAtTo = getEndOfTheDay(moment(values?.date[1]));
      handleSearch(filter);
    } else {
      !(values.value && !values.type) &&
        handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
    }
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select showSearch onChange={onChangeFilter} placeholder="Select type" allowClear>
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div className="filter-divider" />
        {selectedFilter !== "status" &&
          selectedFilter !== "reason" &&
          selectedFilter !== "date" &&
          selectedFilter !== "createdBy" && (
            <Form.Item name="value">
              <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          )}
        {selectedFilter === "createdBy" && (
          <Form.Item name="value">
            <TSRSelect
              showSearch
              initialList={TSRList}
              roles={[
                USER_ROLES.SYSTEM_FULL_ADMINS,
                USER_ROLES.TECHNICIANS,
                USER_ROLES.MTSR,
                USER_ROLES.LT,
                USER_ROLES.TRAINING,
                USER_ROLES.TSS,
                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                USER_ROLES.IT_OPERATIONS,
                USER_ROLES.CUSTOMER_CARE,
              ]}
              depot={AUTH_USER?.depotName}
              placeholder="Please select TSR"
            />
          </Form.Item>
        )}
        {selectedFilter === "reason" && (
          <Form.Item name="value">
            <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
              {TAMReasons?.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {selectedFilter === "status" && (
          <Form.Item name="value">
            <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
              {availableStatuses.map(item => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {selectedFilter === "date" && (
          <Form.Item
            name="date"
            rules={[{ required: true, message: "Please select date range" }]}
            className={classes.root}
          >
            <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
          </Form.Item>
        )}
        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default TAMRequestsSearch;

import React, { useCallback, useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { filterOptions, FILTERS } from "./variables";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import TSRSelect from "components/Common/components/TSRSelect";
import { getUsersList } from "utils/api";

const { Option } = Select;

const useStyles = makeStyles({
  filtersContainer: {
    display: "flex",
    alignItems: "center",
    height: 60,
    overflow: "auto",

    "& .ant-select-selection-overflow": {
      flexWrap: "nowrap",
    },
  },
  filterSelect: {
    "& .ant-select": {
      minWidth: "auto!important",
    },
  },
});

const ArchiveFilter = ({ onFilter, showCategory }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [filterType, setFilterType] = useState([]);
  const { getFilters } = useStoreFilters(FILTER_PAGE.TASK_ARCHIVE);
  const [TSRList, setTSRList] = useState([]);

  const onChange = useCallback(
    value => {
      if (value.includes(FILTERS.CUSTOMER) && value.includes(FILTERS.TSR)) return;
      if (value.length > 2) return;
      setFilterType(value);
    },
    [filterType]
  );

  const handleOnFilter = useCallback(values => {
    const { pageSize } = getFilters();
    const preparedData = Object.keys(values).reduce((a, item) => ({ ...a, [item]: values[item]?.trim() }), {});
    onFilter({ ...preparedData, pageSize });
  }, []);

  useEffect(() => {
    getUsersList({
      role: [USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS].join(),
      pageSize: 500,
    }).then(({ data }) => setTSRList(data.result.items));
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={handleOnFilter}>
        <div className={classnames(classes.filtersContainer)}>
          <Select
            showSearch
            tokenSeparators={[" ", ","]}
            mode="multiple"
            value={filterType}
            placeholder={"Please select type"}
            onChange={onChange}
            allowClear
          >
            {filterOptions.map(item => (
              <Option key={item.value} title={item.name} value={item.value} label={item.name} short={item.short}>
                {item.name}
              </Option>
            ))}
          </Select>

          {filterType.includes(FILTERS.CATEGORY) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="category" rules={[{ required: true, message: "Please select task category" }]}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  size={"default"}
                  placeholder={"Select task category"}
                  allowClear
                >
                  {showCategory.length > 0 &&
                    showCategory.map(
                      item => (
                        <Option key={item} value={item}>
                          {item}
                        </Option>
                      ),
                      this
                    )}
                </Select>
              </Form.Item>
            </>
          )}
          {filterType.includes(FILTERS.CUSTOMER) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="customerId" rules={[{ required: true, message: "Please enter customer ID" }]}>
                <Input
                  style={{ width: "100%" }}
                  size={"default"}
                  placeholder={"Enter customer ID"}
                  allowClear
                  pattern={inputTextPattern}
                  title={inputPatternTitle}
                />
              </Form.Item>
            </>
          )}
          {filterType.includes(FILTERS.TSR) && (
            <>
              <div className="filter-divider" />
              <Form.Item name="assignedTo" rules={[{ required: true, message: "Please select email" }]}>
                <TSRSelect
                  showSearch
                  initialList={TSRList}
                  roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS]}
                  depot={AUTH_USER?.depotName}
                  placeholder="Please select TSR"
                />
              </Form.Item>
            </>
          )}
        </div>
        <CustomButton type="primary" htmlType="submit" text="Filter" size="small" />
      </Form>
    </div>
  );
};

export default ArchiveFilter;

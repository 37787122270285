import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";

const useStyles = makeStyles({
  root: {
    display: "inline-block",
    "&>  .btn:active": {
      borderColor: "transparent",
    },
  },
  menuItem: {
    padding: 15,

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
  },
  menuContainer: {
    "div+div": {
      borderBottom: "1px solid red",
    },
  },
});

const NGIDropdownButton = ({ toggleEl, menuItems = [], hideRoles }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(e => {
    e?.stopPropagation();
    setIsOpen(prevState => !prevState);
  }, []);
  if (!userHasAdminRole()) {
    if (userHasOneOfRoles(hideRoles)) return null;
  }
  return (
    <Dropdown className={classes.root} isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="btn" tag="span">
        {toggleEl}
      </DropdownToggle>

      <DropdownMenu className={classes.menuContainer}>
        {menuItems.map(item => {
          if (item?.roles && !userHasOneOfRoles(item?.roles)) {
            return null;
          }
          if (item?.hideRoles && userHasOneOfRoles(item?.hideRoles)) {
            return null;
          }
          return (
            <div
              key={item.label}
              className={classes.menuItem}
              onClick={() => {
                setIsOpen(false);
                item.callback();
              }}
            >
              {item.label}
            </div>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NGIDropdownButton;

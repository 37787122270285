import React, { useCallback, useState } from "react";
import { Form, Select, Input } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { Option } from "antd/es/mentions";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import { TSRSelector } from "store/TSR/selectors";
import TSRSelect from "components/Common/components/TSRSelect";

const filterOptions = [
  { name: "Customer ID", value: "customerId" },
  { name: "TSR", value: "assignedTo" },
  { name: "Category", value: "category" },
  { name: "Manual Declined", value: "status" },
];

const TaskRequestsSearch = ({ handleSearch }) => {
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.TASK_REQUESTS);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const {
    list: { taskCategory },
  } = useSelector(DictionariesSelector);
  const { TSRList } = useSelector(TSRSelector);

  const onChangeFilter = useCallback(values => {
    setSelectedFilter(values);
    form.setFieldValue("value", null);
  }, []);

  const onSearch = useCallback(values => {
    const { type, value } = values;
    const { pageSize } = getFilters();
    const selectedValue = type === "status" ? "Rejected" : value?.trim();
    handleSearch({ [type]: selectedValue, pageSize });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select showSearch onChange={onChangeFilter} tokenSeparators={[","]} placeholder="Select type" allowClear>
            {filterOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {selectedFilter !== "category" && selectedFilter !== "assignedTo" && selectedFilter !== "status" && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <Input placeholder={"Enter value"} allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          </>
        )}
        {selectedFilter === "category" && selectedFilter !== "status" && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <Select showSearch placeholder={"Select value"}>
                {taskCategory &&
                  taskCategory?.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        )}
        {selectedFilter === "assignedTo" && (
          <>
            <div className="filter-divider" />
            <Form.Item name="value">
              <TSRSelect
                showSearch
                initialList={TSRList}
                roles={[USER_ROLES.TECHNICIANS, USER_ROLES.MTSR]}
                depot={AUTH_USER?.depotName}
                placeholder="Please select TSR"
              />
            </Form.Item>
          </>
        )}
        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default TaskRequestsSearch;

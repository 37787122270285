import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import importIcon from "assets/images/svg/import.svg";
import exportIcon from "assets/images/svg/export.svg";
import { STO_TYPES } from "pages/stock-management/stockTransferOrders/constants";
import { textFormatter } from "components/Common/formatter";
import STODetailsModal from "pages/stock-management/stockTransferOrders/STODetails/STODetailsModal";
import CustomLink from "components/Common/buttons/CustomLink";
import { Collapse, Divider, Pagination } from "antd";
import LoadedAssetItem from "pages/stock-management/trips/tripDetails/LoadedAssetItem";
import NewSTOModal from "pages/stock-management/stockTransferOrders/NewSTO/NewSTOModal";
import NGIDropdownButton from "components/Common/buttons/NGIDropdownButton";
import menuIcon from "assets/images/svg/MenuVertical.svg";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { unassignSTO } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";

const useStyles = makeStyles({
  assetItem: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#f5f5f5",
    borderRadius: 50,
    padding: 9,
    paddingRight: 30,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    marginRight: 20,
    background: "rgba(255, 255, 255, 1)",
  },
  subtitle: {
    marginRight: 10,
    color: "grey",
  },
  rightContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%",
    marginRight: 10,
  },
  outbound: {
    marginLeft: 3,
  },
  inbound: {
    marginRight: 3,
  },
  displayFlex: {
    display: "flex",
  },
  link: {
    fontWeight: 600,
    textDecoration: "underline",
  },
  collapse: {
    marginBottom: 10,
    border: "none",
    borderRadius: "30px",
    backgroundColor: "#f5f5f5",
    "&> .ant-collapse-item:last-child > .ant-collapse-header": {
      borderRadius: "0px 0px 30px 30px",
    },
    "&> .ant-collapse-item": {
      borderBottom: "none",
    },
  },
  collapsePanel: {
    "&> .ant-collapse-header": {
      padding: "0 !important",
      borderBottom: "none",
    },
    "&> .ant-collapse-content": {
      backgroundColor: "#f5f5f5",
      borderRadius: "0px 0px 30px 30px !important",
    },
  },
  description: {
    display: "flex",
    justifyContent: "center",
  },
  viewAll: {
    height: 49,
    display: "flex",
    alignItems: "center",
    marginRight: 13,
    textDecoration: "underline",
  },
  extraContainer: {
    display: "flex",
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
});

const { Panel } = Collapse;

const AssetItem = ({ asset, loadedAssets, offloadedAssets, isEdit, update, tripNumber }) => {
  const classes = useStyles();
  const { stoType, group, condition, quantity, stoId, stoItemId } = asset;
  const [isLoading, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [showSize, setShowSize] = useState(10);
  const [pageOffload, setPageOffload] = useState(1);
  const [showSizeOffload, setShowSizeOffload] = useState(10);

  const openDetails = useCallback(e => {
    e.stopPropagation();
    setIsOpen(true);
  }, []);

  const closeDetails = useCallback(() => setIsOpen(false), []);

  const openEditSto = useCallback(() => setIsEditOpen(true), []);
  const closeEditSto = useCallback(() => setIsEditOpen(false), []);

  const openRemoveSto = useCallback(() => setIsRemoveOpen(true), []);
  const closeRemoveSto = useCallback(() => setIsRemoveOpen(false), []);

  const filterAssets = useCallback(
    (group, condition, assets) => assets?.filter(item => item.group === group && item.condition === condition),
    []
  );

  const filteredLoadedAssets = filterAssets(group, condition, loadedAssets);
  const filteredOffloadedAssets = filterAssets(group, condition, offloadedAssets);

  const loadedAssetsCount = useMemo(
    () => filteredLoadedAssets?.reduce((count, item) => count + item?.assets?.length, 0) || 0,
    [filteredLoadedAssets]
  );

  const offloadedAssetsCount = useMemo(
    () => filteredOffloadedAssets?.reduce((count, item) => count + item?.assets?.length, 0) || 0,
    [filteredOffloadedAssets]
  );

  const paginatedLoadedAssets = useMemo(
    () =>
      filteredLoadedAssets
        ?.reduce((items, item) => [...items, ...item?.assets], [])
        ?.slice((page - 1) * showSize, page * showSize),
    [filteredLoadedAssets, page, showSize]
  );

  const paginatedOffloadedAssets = useMemo(
    () =>
      filteredOffloadedAssets
        ?.reduce((items, item) => [...items, ...item?.assets], [])
        ?.slice((pageOffload - 1) * showSizeOffload, pageOffload * showSizeOffload),
    [filteredOffloadedAssets, pageOffload, showSizeOffload]
  );

  const applyPage = useCallback(page => setPage(page), []);
  const applyPagSize = useCallback((curent, size) => setShowSize(size), []);

  const applyOffloadPage = useCallback(page => setPageOffload(page), []);
  const applyOffloadPagSize = useCallback((current, size) => setShowSizeOffload(size), []);

  const handleRemove = useCallback(() => {
    setLoader(true);
    unassignSTO(tripNumber, {
      stoItemId,
    })
      .then(() => {
        closeRemoveSto();
        setLoader(false);
        successMessage("STO was removed successfully");
        update();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [tripNumber, stoId, stoItemId]);

  return (
    <>
      <Collapse className={classes.collapse} collapsible={loadedAssets?.length} expandIconPosition={"end"}>
        <Panel
          key={"panel"}
          className={classes.collapsePanel}
          extra={
            <div className={classes.extraContainer}>
              <div className={classes.viewAll}>View all assets</div>
              {isEdit &&
                userHasOneOfRoles([
                  USER_ROLES.SYSTEM_FULL_ADMINS,
                  USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                  USER_ROLES.IT_OPERATIONS,
                  USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                  USER_ROLES.WAREHOUSE,
                  USER_ROLES.FINANCE,
                ]) && (
                  <NGIDropdownButton
                    toggleEl={
                      <div className={classes.actions}>
                        <img src={menuIcon} alt="remove" />
                      </div>
                    }
                    menuItems={[
                      {
                        label: "Edit",
                        callback: openEditSto,
                      },
                      {
                        label: "Remove",
                        callback: openRemoveSto,
                      },
                    ]}
                  />
                )}
            </div>
          }
          showArrow={false}
          header={
            <div className={classes.assetItem}>
              <div className={classes.iconContainer}>
                <div className={classes.icon}>
                  <img
                    className={stoType === STO_TYPES.INBOUND ? classes.inbound : classes.outbound}
                    src={stoType === "Inbound" ? importIcon : exportIcon}
                    alt="sto type"
                  />
                </div>
                <div>
                  <CustomLink className={classes.link} onClick={openDetails}>
                    {`${group} (${stoId})`}
                  </CustomLink>
                </div>
              </div>
              <div className={classes.rightContainer}>
                <div className={classes.displayFlex}>
                  <span className={classes.subtitle}>Condition</span>
                  <b>{textFormatter(condition)}</b>
                </div>
                <div className={classes.displayFlex}>
                  <span className={classes.subtitle}>QTY</span>
                  <b>{textFormatter(quantity)}</b>
                </div>
              </div>
            </div>
          }
          style={{ padding: 0, margin: 0 }}
        >
          <h6>Loaded assets ({loadedAssetsCount}):</h6>
          {paginatedLoadedAssets?.length ? (
            <>
              {paginatedLoadedAssets?.map(item => (
                <LoadedAssetItem key={item.id} item={item} />
              ))}
              <Pagination
                onChange={applyPage}
                showSizeChanger
                onShowSizeChange={applyPagSize}
                defaultPageSize={showSize}
                defaultCurrent={page}
                total={loadedAssetsCount || 0}
              />
            </>
          ) : (
            <div className={classes.description}>
              <p>No loaded assets</p>
            </div>
          )}
          <Divider />

          <h6>Offloaded assets ({offloadedAssetsCount}):</h6>
          {paginatedOffloadedAssets?.length ? (
            <>
              {paginatedOffloadedAssets?.map(item => (
                <LoadedAssetItem key={item.id} item={item} />
              ))}
              <Pagination
                onChange={applyOffloadPage}
                showSizeChanger
                onShowSizeChange={applyOffloadPagSize}
                defaultPageSize={showSizeOffload}
                defaultCurrent={pageOffload}
                total={offloadedAssetsCount || 0}
              />
            </>
          ) : (
            <div className={classes.description}>
              <p>No offloaded assets</p>
            </div>
          )}
        </Panel>
      </Collapse>
      {isOpen && <STODetailsModal id={stoId} onClose={closeDetails} />}
      {isEditOpen && (
        <NewSTOModal onClose={closeEditSto} id={stoId} update={update} isEditTrip={isEdit} tripNumber={tripNumber} />
      )}

      {isRemoveOpen && (
        <ConfirmationModal
          loading={isLoading}
          title="Confirm removing STO"
          description="Are you sure you want to remove this STO?"
          confirmBtnTitle="Confirm"
          cancelBtnTitle="Cancel"
          onCancel={closeRemoveSto}
          onConfirm={handleRemove}
        />
      )}
    </>
  );
};

export default AssetItem;

import React, { useCallback, useState } from "react";
import { DatePicker, Form, Input, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import NGISelect from "components/Common/inputs/NGISelect";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import { makeStyles } from "@material-ui/core/styles";
import { filterOptions } from "pages/warehouse/stoveRepair/variables";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { inputPatternTitle, inputTextPattern } from "components/Common/inputs/variables";
import TSRSelect from "components/Common/components/TSRSelect";

const { RangePicker } = DatePicker;
const { Option } = Select;

const useStyles = makeStyles({
  value: {
    width: "auto !important",
  },
});

const StoveRepairFilter = ({ onFilter, TSRList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const { getFilters } = useStoreFilters(FILTER_PAGE.STOVE_REPAIR);
  const [activeFilter, setFilter] = useState(null);
  const [dateRange, setDateRange] = useState(null);

  const disableDate = useCallback(current => current && current.valueOf() > Date.now(), []);

  const onDateRangeChange = useCallback(value => setDateRange(value), []);

  const handleFilterChange = useCallback(
    value => {
      setFilter(value);
      form.setFieldsValue({
        value: null,
        range: null,
      });
    },
    [form]
  );

  const onFinish = useCallback(
    values => {
      if (!(values.value && !values.type)) {
        const { customerId, stoveId, collectedBy, timeStart, timeEnd, ...normalizedData } = getFilters();
        if (values.type === "dateRange") {
          onFilter({
            ...normalizedData,
            timeStart: getStartOfTheDay(dateRange[0]),
            timeEnd: getEndOfTheDay(dateRange[1]),
          });
        } else if (!values.type) onFilter(normalizedData);
        else onFilter({ ...normalizedData, [values.type]: values.value?.trim() });
      }
    },
    [dateRange]
  );

  return (
    <>
      <div className="custom-filter">
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
          <Form.Item name="type">
            <NGISelect
              showSearch
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              size="default"
              placeholder="Select type"
              allowClear
              onChange={handleFilterChange}
            >
              {filterOptions.length > 0 &&
                filterOptions.map(item => (
                  <Option key={item} value={item.value}>
                    {item.name}
                  </Option>
                ))}
            </NGISelect>
          </Form.Item>
          <div className="filter-divider" />

          {activeFilter === "dateRange" && (
            <Form.Item name="range" className={classes.value}>
              <RangePicker inputReadOnly={true} onChange={onDateRangeChange} disabledDate={disableDate} />
            </Form.Item>
          )}

          {activeFilter === "collectedBy" && (
            <Form.Item name="value">
              <TSRSelect
                showSearch
                initialList={TSRList}
                roles={[
                  USER_ROLES.SYSTEM_FULL_ADMINS,
                  USER_ROLES.TECHNICIANS,
                  USER_ROLES.MTSR,
                  USER_ROLES.LT,
                  USER_ROLES.TRAINING,
                  USER_ROLES.TSS,
                ]}
                depot={AUTH_USER?.depotName}
                placeholder="Please select TSR"
              />
            </Form.Item>
          )}

          {activeFilter !== "dateRange" && activeFilter !== "collectedBy" && (
            <Form.Item name="value" className={classes.value}>
              <Input placeholder="Enter value" allowClear pattern={inputTextPattern} title={inputPatternTitle} />
            </Form.Item>
          )}

          <CustomButton type="primary" htmlType="submit" text="Filter" size="small" />
        </Form>
      </div>
    </>
  );
};

export default StoveRepairFilter;

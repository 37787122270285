export const TRIP_STATUSES = {
  PENDING: "Pending",
  PLANNED: "Planned",
  IN_TRANSIT: "InTransit",
  COMPLETED: "Completed",
  STOPPED: "Stopped",
  CANCELLED: "Cancelled",
  EXPIRED: "Expired",
};

export const searchOptions = [
  {
    value: "tripNumber",
    title: "Trip Number",
  },
  {
    value: "status",
    title: "Status",
  },
  {
    value: "tripDate",
    title: "Date",
  },
];

export const editableStatuses = [TRIP_STATUSES.PLANNED, TRIP_STATUSES.PENDING, TRIP_STATUSES.IN_TRANSIT];

export const dateFormat = "DD/MM/YYYY";

import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import { taskColumns } from "./taskColumns";
import Filters from "components/Common/filters/operations/tasksFilter";
import { clearErrors, getTaskCategory, getTaskStatus } from "store/operations/tasks/actions";
import { retrieveAllTasks, assignBulkTaskApi, getUsersList } from "utils/api";
import { Form, Modal, Select, Space } from "antd";
import { successMessage, defaultNgiErrorMethod, warnMessage, errorMessage } from "components/Common/responses/message";
import { TaskSelector } from "store/operations/tasks/selectors";
import CancelTaskModal from "./CancelTaskModal";
import { NgiErrorMethod, phoneBeautifier } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import CustomButton from "components/Common/buttons/CustomButton";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import cancelIcon from "assets/images/svg/cancel-white.svg";
import NGITooltip from "components/Common/utils/NGITooltip";
import useStoreFilters from "customHooks/useStoreFilters";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import { makeStyles } from "@material-ui/core/styles";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGISelect from "components/Common/inputs/NGISelect";
import { useLocation } from "react-router";
import { CANCEL_STATUSES, taskSourceFormatter } from "pages/operations/tasks/variables";
import { userHasOneOfRoles } from "utils/helpers/functions";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import addIcon from "assets/images/svg/plus-circle-white.svg";
import workerIcon from "assets/images/svg/worker.svg";
import { uniq } from "lodash";

const allowedStatuses = ["Assigned", "Incompletable"];

const useStyles = makeStyles({
  root: {
    width: "400px!important",
  },
  button: { marginRight: 10 },
});
const RetrieveTasks = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { errorCancelTask, cancelledTask, cancelTaskPostFail, cancelTaskPostSuccess, taskCategory, taskStatus } =
    useSelector(TaskSelector);
  const { Option } = Select;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [agentList, setAgentList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [taskList, setTaskList] = useState([]);
  const [rowList, setRowList] = useState([]);
  const [currentFilterOptions, setCurrentFilterOptions] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { filters, getFilters, setFilters } = useStoreFilters(FILTER_PAGE.TASK);
  const searchParams = useLocation().search;
  const customerId = new URLSearchParams(searchParams).get("customerId");
  const hideRoles = [
    USER_ROLES.CUSTOMER_CARE,
    USER_ROLES.CUSTOMER_CARE_LEADERSHIP,
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.A_DSM,
    USER_ROLES.D_SM,
    USER_ROLES.DEPOT_CLERK,
    USER_ROLES.GENERAL,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
    USER_ROLES.FINANCE,
    USER_ROLES.TSS,
    USER_ROLES.A_SM,
  ];

  useEffect(() => {
    dispatch(getTaskCategory());
    dispatch(getTaskStatus());
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));

    return () => dispatch(clearErrors());
  }, []);

  useEffect(() => {
    if (cancelTaskPostSuccess && cancelledTask.hasOwnProperty("result")) {
      successMessage(cancelledTask["result"]);
      setLoader(true);
      setTimeout(() => handleOnFilter(filters), 1500);
      closeDeleteModal();
    }
    if (cancelTaskPostFail && errorCancelTask) {
      const message = NgiErrorMethod(errorCancelTask);
      errorMessage(message);
    }
  }, [cancelTaskPostFail, cancelTaskPostSuccess, errorCancelTask, cancelledTask]);

  useEffect(() => {
    handleOnFilter(customerId ? { customerId } : filters);
  }, []);

  useEffect(() => {
    getUsersList({
      role: [USER_ROLES.TECHNICIANS, USER_ROLES.MTSR, USER_ROLES.TSS].join(),
      depot: AUTH_USER?.depotName,
      pageSize: 1000,
    }).then(res => {
      setAgentList(res?.data?.result?.items);
    });
  }, []);
  const handleChange = selectedAgent => {
    setSelectedOption(JSON.parse(selectedAgent));
  };

  const onReset = () => {
    form.resetFields();
  };
  const investigationState = useMemo(() => {
    const investigationCount = taskList?.result?.items.filter(
      item => selectedRowKeys.includes(item.id) && item.category === "Investigation"
    );
    return {
      hasError: investigationCount?.length ? investigationCount?.length !== selectedRowKeys?.length : false,
      isInvestigation: investigationCount?.length === selectedRowKeys?.length,
    };
  }, [selectedRowKeys, taskList]);

  const showModal = useCallback(() => {
    if (!investigationState.hasError) {
      setRowList(selectedRowKeys);
      onReset();
      setVisible(true);
      return;
    }
    errorMessage("Need to separate tasks for reassigning. Investigation tasks should be reassigned separately.");
  }, [selectedRowKeys, investigationState]);

  const handleOk = async () => {
    const options = {
      assignTo: selectedOption.email,
      assignToName: selectedOption.name,
      assignToDepot: selectedOption.depot,
      taskIds: rowList,
    };
    setLoader(true);
    setVisible(false);
    await assignBulkTaskApi(options)
      .then(() => {
        const filters = getFilters();
        setTimeout(() => {
          successMessage(`Task was reassigned successfully`);
          setLoader(false);
          handleOnFilter(filters);
        }, 2000);
      })
      .catch(error => {
        setVisible(false);
        setLoader(false);
        if (error?.response?.data?.errors[0]?.code === "TEAM_IS_NOT_ELIGIBLE") {
          errorMessage("Team is not eligible for this task type and TSR.");
        } else {
          defaultNgiErrorMethod(error);
        }
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOnFilter = (options = {}) => {
    setLoader(true);
    let filterOptions = options;

    if (filterOptions.hasOwnProperty("customerPhone")) filterOptions = phoneBeautifier(filterOptions["customerPhone"]);

    const myConstOptions = AUTH_USER?.roles?.includes(USER_ROLES.SYSTEM_FULL_ADMINS)
      ? { ...filterOptions }
      : { ...filterOptions, depot: AUTH_USER?.depotName };
    setCurrentFilterOptions(myConstOptions);
    setFilters(myConstOptions);
    retrieveAllTasks(myConstOptions)
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        if (items.length === 0) {
          warnMessage("Task was filtered");
        }
        setTaskList(res?.data);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        setTaskList([]);
        defaultNgiErrorMethod(err);
      });
  };
  /**
   * Cancel Task Logic
   */

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState(null);

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
    setTaskToDelete(null);
  };
  const openDeleteModal = () => setIsDeleteModalVisible(true);

  const onDelete = useCallback(task => {
    setTaskToDelete(task);
    openDeleteModal();
  }, []);

  const columns = [
    ...taskColumns,
    ...((currentFilterOptions["status"] === CANCEL_STATUSES.INCOMPLETABLE ||
      currentFilterOptions["status"] === CANCEL_STATUSES.ASSIGNED) &&
    !userHasOneOfRoles(hideRoles)
      ? [
          {
            title: "",
            key: "id",
            dataIndex: "id",
            width: "14%",
            render: (cellContent, task) => (
              <ButtonGroup
                hideRoles={[USER_ROLES.CUSTOMER_CARE, USER_ROLES.INTERNAL_AUDITOR]}
                tooltipTitle={taskSourceFormatter(task) === "CC" ? "Convert Task" : "Cancel Task"}
                icon={cancelIcon}
                handleClick={() => onDelete(task)}
              />
            ),
          },
        ]
      : []),
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: rows => {
      setSelectedRowKeys(prevState => uniq([...prevState, ...rows]));
    },
    onSelect: (value, isSelect) => {
      if (!isSelect) {
        setSelectedRowKeys(prevState => [...prevState.filter(item => item !== value.id)]);
      }
    },
    onSelectAll: isSelect => {
      if (!isSelect) {
        setSelectedRowKeys([]);
      }
    },
    renderCell: (checked, record, index, node) => {
      if (!allowedStatuses.includes(record.status)) {
        return <NGITooltip title="Incorrect status for reassigning or cancelling">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      if (!allowedStatuses.includes(record.status)) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper with-buttons">
            <ButtonGroupContainer color={"primary"}>
              <ButtonGroup
                roles={[
                  USER_ROLES.SYSTEM_FULL_ADMINS,
                  USER_ROLES.SALES_SUPPORT,
                  USER_ROLES.D_SM,
                  USER_ROLES.A_SM,
                  USER_ROLES.OPERATION_MANAGERS,
                ]}
                color={"primary"}
                link="/operations/create-task"
                tooltipTitle="Create Task"
                icon={addIcon}
              />

              {!!selectedRowKeys.length && (
                <>
                  <ButtonGroup
                    hideRoles={[
                      USER_ROLES.CUSTOMER_CARE,
                      USER_ROLES.INTERNAL_AUDITOR,
                      USER_ROLES.A_DSM,
                      USER_ROLES.D_SM,
                      USER_ROLES.OPERATION_MANAGERS,
                      USER_ROLES.GENERAL,
                      USER_ROLES.IT_OPERATIONS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.FINANCE,
                    ]}
                    color={"primary"}
                    tooltipTitle="Reassign"
                    handleClick={showModal}
                    icon={workerIcon}
                  />
                  <ButtonGroup
                    hideRoles={[
                      USER_ROLES.A_DSM,
                      USER_ROLES.D_SM,
                      USER_ROLES.IT_OPERATIONS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.GENERAL,
                    ]}
                    color={"primary"}
                    tooltipTitle="Bulk cancel"
                    icon={cancelIcon}
                    handleClick={openDeleteModal}
                  />
                </>
              )}
            </ButtonGroupContainer>

            <Filters
              onFilter={handleOnFilter}
              isLoading={isLoading}
              showStatus={taskStatus}
              showCategory={taskCategory}
            />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.TASK}
              isLoading={isLoading}
              columns={columns}
              data={taskList}
              updateList={handleOnFilter}
              rowSelection={userHasOneOfRoles(hideRoles) ? null : rowSelection}
              rowKey={record => record.id}
            />
          </Col>
        </Container>
      </div>

      {visible && (
        <Modal
          className={classes.root}
          title={`Reassign To ${investigationState.isInvestigation ? "TSS" : "User"}`}
          open={visible}
          onCancel={handleCancel}
          closable={false}
          footer={null}
        >
          <NGIForm name="reassignToTSR" form={form} onFinish={handleOk}>
            <Row>
              <NGIFormItem
                name="assigneeId"
                rules={[
                  {
                    required: true,
                    message: `Please select ${investigationState.isInvestigation ? "TSS" : "a user"}`,
                  },
                ]}
              >
                <NGISelect
                  showSearch
                  loading={!agentList?.length}
                  style={{ width: 300 }}
                  placeholder={`Select ${investigationState.isInvestigation ? "TSS" : "a user"}`}
                  onChange={handleChange}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {agentList.length > 0 &&
                    agentList
                      .filter(item =>
                        investigationState.isInvestigation
                          ? item.roles.includes(USER_ROLES.TSS)
                          : item.roles.includes(USER_ROLES.TSS) ||
                            item.roles.includes(USER_ROLES.TECHNICIANS) ||
                            item.roles.includes(USER_ROLES.MTSR)
                      )
                      .map(
                        item => (
                          <Option key={item.id} value={JSON.stringify(item)}>
                            {item.name || item.id}
                          </Option>
                        ),
                        this
                      )}
                </NGISelect>
              </NGIFormItem>
              <Col span={12}>
                <Space>
                  <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
                  <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
                </Space>
              </Col>
            </Row>
          </NGIForm>
        </Modal>
      )}

      {isDeleteModalVisible && (
        <CancelTaskModal
          onClose={closeDeleteModal}
          updateTable={handleOnFilter}
          initialValues={taskToDelete}
          bulkValues={selectedRowKeys}
          resetSelectedRowKeys={setSelectedRowKeys}
          setLoader={setLoader}
        />
      )}
    </>
  );
};

export default RetrieveTasks;
